import React, {useEffect, useState} from "react";
import {FaSearch} from "react-icons/fa";
import ResourceCard from "./ui/resource-card";
import styles from './filter-resources.module.css'
import api from "../../api";

export default function FilterResources() {
    const options = [
        {value: '', text: 'All'},
        {value: 'Guide', text: 'Guide'},
        {value: 'Resource', text: 'Resource'},
        {value: 'Template', text: 'Template'},
    ];

    const [resources, setResources] = useState([]);
    const [selected, setSelected] = useState(options[0].value);
    const [input, setInput] = useState("");
    const [results, setResults] = useState([]);
    const [filtered, setFiltered] = useState([]);

    const url = api;

    const getAllResources = async () => {
        try {
            const response = await fetch(url + 'app/allResources');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const approvedResources = data.resources.filter(resource => resource.status === "approved");

            setResources(approvedResources);
            setResults(approvedResources)

        } catch (error) {
            console.error('Error fetching announcements data:', error);
        }
    }

    useEffect(() => {
        getAllResources();
    }, []);

    const fetchDataInput = (value) => {
        if (value === '') {
            setResults(resources);
            return;
        }
        const res = resources.filter((resource) => {
            return (
                value &&
                resource &&
                resource.title &&
                resource.title.toLowerCase().includes(value)
            );
        });
        setResults(res);
    };

    const fetchDataSelect = (value) => {
        if (value === '') {
            setResults(resources);
            return;
        }
        const res = resources.filter((resource) => {
            return (
                value &&
                resource &&
                resource.type &&
                resource.type.includes(value)
            );
        });
        setResults(res);
    };

    const handleChangeSelect = event => {
        setSelected(event.target.value);
        setInput('');
        fetchDataSelect(event.target.value);
    };

    const handleChangeInput = event => {
        setInput(event);
        fetchDataInput(event);
    };

    useEffect(() => {
        if (results.length > 0) {
            let newList = [...results];
            if (input) {
                newList = newList.filter(i => i.title.toLowerCase().includes(input));
            }
            if (selected) {
                newList = newList.filter(i => i.type.includes(selected));
            }
            setFiltered(newList);
        } else {
            setFiltered(results);
        }
    }, [results, input, selected]);

    return (
        <>
            <div className={'search-container section-col'}>
                <div className={'filter-wrapper search-input mb-5 mb-0d'}>
                    <select id={'filter'} className={''} value={selected} onChange={handleChangeSelect}>
                        {options.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={'search-wrapper search-input'}>
                    <input id='search-input' placeholder="Search Resources or guides" value={input}
                           onChange={(e) => handleChangeInput(e.target.value)}
                    />
                    <FaSearch size={20} className="search-icon"/>
                </div>
            </div>
            <div className={'search-results'}>
                {filtered.length > 0 ?
                    <div className={styles.grid}>
                        {
                            filtered.map(filter => (
                                <div className={styles.col} key={filter.resourcesId}>
                                    <ResourceCard id={filter.resourcesId}
                                                  title={filter.title}
                                                  content={filter.content}
                                                  type={filter.type}
                                                  status={filter.status}
                                                  locationID={filter.locationID}
                                                  management={0}
                                    /></div>

                            ))
                        }
                    </div>
                    :
                    <p>No results found. Please try again.</p>
                }
            </div>
        </>

    );
}