import React, {useEffect, useMemo, useState} from 'react';
import style from "./events-offers.module.css";
import HeadingTwo from "./ui/heading-two";
import api from "../../api";
import Pagination from "../pagination";
import ResourceCard from "./ui/resource-card";

let PageSize = 6;

export default function PendingResources({user}) {

    const [currentPage, setCurrentPage] = useState(1);
    const [resources, setResources] = useState([]);
    const [businessName, setBusinessName] = useState('');

    const url = api;

    const getBusinessInfo = async () => {
        try {
            const response = await fetch(url + 'app/businessSelection/' + user.businessId);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBusinessName(data.business.businessName);

        } catch (error) {
            console.error('Error fetching weather data:', error);
        }
    };

    const getPendingResources = async () => {
        try {
            const response = await fetch(url + 'app/allPendingResources/');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) {
                setResources(data.resources);
            }
        } catch (error) {
            console.error('Error fetching resources data:', error);
        }
    }

    useEffect(() => {
        getBusinessInfo();
        getPendingResources();
    }, [businessName]);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return resources.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, resources]);

    const items = currentTableData.map((resource) => {
        return <div key={resource.resourcesId}>
            <ResourceCard id={resource.resourcesId} title={resource.title} content={resource.content}
                          type={resource.type} status={resource.status} management={1}/>
        </div>;
    });

    return (
        <div className={'bg-light-green mb-10 ' + style.cardOffers}>
            <HeadingTwo title={'Draft'} className={''}/>
            {
                !items.length ?
                    <p>There are no resources in drafts at this time.</p>
                    :
                    <div className={"resource-card-management"}>{items}</div>
            }
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={resources.length}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>
    );
}