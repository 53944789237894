import React, {useState} from "react";
import Heading from "../../components/towncrier/ui/heading";
import {Navigate, useLocation} from "react-router-dom";
import UpdateResourceForm from "../../components/towncrier/update-resource-form";

export default function UpdateResource() {
    let location = useLocation();
    const [user] = useState(JSON.parse(localStorage.getItem("user")));

    if (user.role !== 1) {
        return <Navigate to={'/'}/>
    }

    return (
        <main className={'update-resource towncrier-content'}>
            <div className='section'>
                <Heading title={'Update ' + location.state.title}/>
                <UpdateResourceForm status={location.state.status} resourcesId={location.state.resourcesId}
                                    resourceContent={location.state.content} locationID={location.state.locationID}
                                    resourceTitle={location.state.title} resourceType={location.state.type}/>
            </div>
        </main>
    );
}