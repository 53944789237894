import React, {useState} from 'react';
import styles from './join-event-modal.module.css'
import HeadingTwo from "./ui/heading-two";
import style from "./update-business.module.css";
import api from "../../api";

export default function JoinEventModal({show, handleClose, title, date, eventId}) {

    const [participantsNames, setParticipantsNames] = useState('');
    const [participantsNumber, setParticipantsNumber] = useState('');
    const [comment, setComment] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const url = api;

    if (!show) {
        return null;
    }

    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());
        formJson.eventId = eventId;

        const initPut = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/joinEvent', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    setError('');
                    alert("Registered to event!");
                    window.location.reload();
                } else {
                    setError('You did not join the event. Please check your information and try again.')
                }
            })
            .catch(error => console.error(error));

    }

    return (
        <div className={styles.modal}>
            <div className={styles.modalMain}>
                <div className={'section-col ' + styles.header}>
                    <HeadingTwo className={styles.heading} title={'Join this Event'}/>
                    <button className={styles.close} onClick={handleClose}>Close</button>
                </div>

                <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
                    <div className={'section-col'}>
                        <label>
                            Event Title <input className={style.mailingInput + ' search-input ' + styles.readOnly}
                                               placeholder='Name'
                                               required={true} type='text' name="title" value={title} readOnly/>
                        </label>
                        <label>
                            Date <input className={style.mailingInput + ' search-input ' + styles.readOnly}
                                        placeholder='Date' required={true}
                                        type='text' name="date" value={date} readOnly/>
                        </label>
                    </div>

                    <label>
                        Participants Names <input className={style.mailingInput + ' search-input'}
                                                  placeholder='Participants Names' required={true} type='text'
                                                  name="participantsNames" value={participantsNames}
                                                  onChange={e => setParticipantsNames(e.target.value)}/>
                    </label>
                    <p>Separate the names with a comma</p>
                    <div className={'section-col'}>
                        <label>
                            Number of Participants <input className={style.mailingInput + ' search-input'}
                                                          placeholder='Number of Participants' required={true}
                                                          type='number'
                                                          name="participantsNumber" value={participantsNumber}
                                                          onChange={e => setParticipantsNumber(e.target.value)}/>
                        </label>
                        <label>
                            Your Email <input className={style.mailingInput + ' search-input'}
                                              placeholder='Email' required={true} type='email'
                                              name="email" value={email}
                                              onChange={e => setEmail(e.target.value)}/>
                        </label>
                    </div>
                    <label>
                        Comments <textarea className={style.mailingInput + ' search-input'} placeholder='Comments'
                                           required={true} rows='4' name="comment" value={comment}
                                           onChange={e => setComment(e.target.value)}/>
                    </label>
                    <label><input type="checkbox" name="accept" value="1" aria-invalid="false" required={true}/><span
                        className="">Yes I agree to share my email address with the event organizer.</span></label>
                    <div className={'align-center'}>
                        <button className={styles.button} type="submit">Register</button>
                        {error ? <p className={style.error}>{error}</p> : null}
                    </div>
                </form>

            </div>
        </div>
    )
}