import React, {useState} from 'react';
import styles from "./ui/button.module.css";
import style from './login-form.module.css'
import {useNavigate} from "react-router-dom";
import api from "../../api";
import Button from "./ui/button";

export default function CreateResourceForm({userLocation}) {

    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [error, setError] = useState('');

    const options = [
        {value: 'Resource', text: 'Resource'},
        {value: 'Guide', text: 'Guide'},
        {value: 'Template', text: 'Template'},
    ];

    const url = api;
    const navigate = useNavigate();

    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());
        //formJson.locationID = userLocation;
        formJson.locationID = 1;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/createResource', options)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Resource submitted!");
                    navigate('/resource-library');
                } else {
                    setError('We couldn’t submit your resource. Please check the information entered and try again.')
                }
            })
            .catch(error => console.error(error));
    }

    return (
        <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
            <label>
                Resource Title <input className={style.mailingInput + ' search-input '} placeholder='Resource Title'
                                      required={true} type='text' name="title" value={title}
                                      onChange={e => setTitle(e.target.value)}/>
            </label>
            <label>
                Business Category
                <div className={style.mailingInput + ' search-input'}>
                    <select id={'filter'}
                            className={''}
                            value={type}
                            required={true}
                            name="type"
                            onChange={e => setType(e.target.value)}>
                        {options.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                    </select>
                </div>
            </label>
            <label>
                Content <textarea className={style.mailingInput + ' search-input'} placeholder='Content'
                                  required={true} rows='4' name="content" value={content}
                                  onChange={e => setContent(e.target.value)}/>
            </label>
            <div>
                <button className={styles.button + ' mr-5'} type="submit">Publish</button>
                <Button text={'Go Back'} href={'..'}/>
            </div>
            {error ? <p className={style.error}>{error}</p> : null}
        </form>
    );
}