import React, {useState} from 'react';
import styles from './resource-card.module.css'
import {Link} from "react-router-dom";
import style from "../update-business.module.css";
import api from "../../../api";

export default function ResourceCard({id, type, title, content, status, management, locationID}) {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [error, setError] = useState();
    let slug = title.toLowerCase().replaceAll(' ', '-');

    let url = api;

    function handleApprove() {

        // Or you can work with it as a plain object:
        const formJson = {
            status: 'approved',
            resourcesId: id
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/resourceStatus', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Resource published");
                    window.location.reload();
                } else {
                    setError('We couldn’t update the resource. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    function handleDeny() {

        // Or you can work with it as a plain object:
        const formJson = {
            status: 'denied',
            resourcesId: id
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/resourceStatus', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Resource put in the trash.");
                    window.location.reload();
                } else {
                    setError('We couldn’t update the resource. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    function handlePending() {

        // Or you can work with it as a plain object:
        const formJson = {
            status: 'pending',
            resourcesId: id
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/resourceStatus', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Resource put in the drafts.");
                    window.location.reload();
                } else {
                    setError('We couldn’t update the resource. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    function handleDelete() {

        const confirmDelete = window.confirm("Are you sure you want to delete this resource?");

        if (confirmDelete) {
            // Or you can work with it as a plain object:
            const formJson = {
                resourcesId: id
            }

            const initPut = {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(formJson)
            };

            fetch(url + 'app/deleteResource', initPut)
                .then(response => response.json())
                .then(data => {
                    if (data.isValid) {
                        alert("Resource deleted succesfully.");
                        window.location.reload();
                    } else {
                        setError('We couldn’t delete the resource. Please try again.')
                    }
                })
                .catch(error => console.error(error));
        }

    }

    return (
        <div>
            <p className={styles.type}><span>{type}</span></p>
            <p className={styles.title}>{title}</p>
            <p>
                {content.length > 250 ?
                    `${content.substring(0, 85)}...` : content
                }
            </p>
            {
                user.role === 1 && management === 1 ?
                    <div className={'align-center mt-10'}>
                        {status === 'pending' ?
                            <>
                                <button className={styles.button + ' mr-5'} onClick={e => {
                                    handleApprove()
                                }}>Publish
                                </button>
                                <button className={styles.button} onClick={e => {
                                    handleDeny()
                                }}>Trash
                                </button>
                            </>
                            : status === 'denied' ?
                                <>
                                    <button className={styles.button + ' mr-5'} onClick={e => {
                                        handleApprove()
                                    }}>Publish
                                    </button>
                                    <button className={styles.button + ' mr-5'} onClick={e => {
                                        handleDelete()
                                    }}>Delete
                                    </button>
                                </>

                                :
                                <>
                                    <button className={styles.button + ' mr-5'} onClick={e => {
                                        handlePending()
                                    }}>Draft
                                    </button>
                                    <button className={styles.button} onClick={e => {
                                        handleDeny()
                                    }}>Trash
                                    </button>
                                </>
                        }
                        {error ? <p className={style.error}>{error}</p> : null}
                    </div>
                    :
                    <span><Link to={slug} state={{
                        id: id,
                        type: type,
                        title: title,
                        slug: slug,
                        content: content,
                        status: status,
                        locationID: locationID
                    }}>View More</Link></span>
            }
        </div>
    )
}