import HeadingTwo from "./ui/heading-two";
import styles from "./events-offers.module.css"
import React, {useEffect, useState} from "react";
import {HiOutlineLocationMarker} from "react-icons/hi";
import IconText from "./ui/icon-text";
import apiUrl from "../../api"
import {Link} from "react-router-dom";
import Button from "./ui/button";

export default function EventsOffers() {

    const [announcements, setAnnouncements] = useState([]);
    const [news, setNews] = useState([]);
    const [events, setEvents] = useState([]);
    const [offers, setOffers] = useState([]);

    let url = apiUrl;
    const getAnnouncements = async () => {
        try {
            const response = await fetch(url + 'app/eventAgenda');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) {
                setAnnouncements(data.announcements);
            }

        } catch (error) {
            console.error('Error fetching announcements data:', error);
        }
    }

    const getLatestNews = async () => {
        try {
            const response = await fetch(url + 'app/newsLatest');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) setNews(data.news);

        } catch (error) {
            console.error('Error fetching news data:', error);
        }
    }

    const getLatestEvents = async () => {
        try {
            const response = await fetch(url + 'app/latestEvents/4');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) setEvents(data.events);

        } catch (error) {
            console.error('Error fetching events data:', error);
        }
    }

    const getLatestOffers = async () => {
        try {
            const response = await fetch(url + 'app/latestOffers/6');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) setOffers(data.offers);

        } catch (error) {
            console.error('Error fetching offers data:', error);
        }
    }

    useEffect(() => {
        getAnnouncements();
        getLatestNews();
        getLatestEvents();
        getLatestOffers();
    }, []);

    function months(month) {
        switch (month) {
            case '01':
                return 'January';
            case '02':
                return 'February';
            case '03':
                return 'March';
            case '04':
                return 'April';
            case '05':
                return 'May';
            case '06':
                return 'June';
            case '07':
                return 'July';
            case '08':
                return 'August';
            case '09':
                return 'September';
            case '10':
                return 'October';
            case '11':
                return 'November';
            case '12':
                return 'December';
            default:
                return '';
        }
    }

    return (
        <>
            <div className={styles.eventContainer}>
                <div className={'bg-light-green ' + styles.card}>
                    <HeadingTwo title={'Upcoming Events'} className={''}/>
                    { events.length ?
                        events.map(event => {
                            const startingDate = event.startingDate.split('T');
                            const endingDate = event.endingDate.split('T');

                            const startingDay = startingDate[0].split('-');
                            const endingDay = endingDate[0].split('-');
                            //const startingHours = startingDate[1].split(':');
                            //const endingHours = endingDate[1].split(':');

                            const startingMonth = months(startingDay[1]);
                            const startingYear = startingDay[0];
                            const endingMonth = months(endingDay[1]);
                            const endingYear = endingDay[0];
                            //const startingTime = startingHours[0] + ':' + startingHours[1];
                            //const endingTime = endingHours[0] + ':' + endingHours[1];

                            let day = '';
                            let month = '';
                            let year = '';
                            //let time = '';

                            //let startingDateEvent = startingMonth.substring(0,3) + '. ' + startingDay[2] + ', ' + startingYear;
                            //let endingDateEvent = endingMonth.substring(0,3) + '. ' + endingDay[2] + ', ' + endingYear;

                            if (startingYear !== endingYear) {
                                year = startingYear + ' - ' + endingYear;
                            } else {
                                year = endingYear;
                            }

                            if (startingMonth !== endingMonth) {
                                month = startingMonth.substring(0,3) + '. - ' + endingMonth.substring(0,3) + '.';
                            } else {
                                month = endingMonth;
                            }

                            if (startingDay[2] !== endingDay[2]) {
                                day = startingDay[2] + ' - ' + endingDay[2];
                            } else if (startingDay[2] === endingDay[2]) {
                                day = endingDay[2];
                            }

                            //if (startingTime !== endingTime) {
                            //    time = 'From ' + startingTime + ' to ' + endingTime;
                            //} else {
                            //    time = endingTime;
                            //}

                            return (
                                <div key={event.eventsId} className={styles.eventWrapper}>
                                    <div className={styles.eventDate}>
                                        <p className={styles.day}>{day}</p>
                                        <p className={styles.month}>{month} {year}</p>
                                    </div>
                                    <hr/>
                                    <div className={styles.eventInfo}>
                                        <p className={styles.eventTitle}>{event.title}</p>
                                        <IconText icon={<HiOutlineLocationMarker size={20}/>} text={event.address}
                                                  style={styles.icon}/>
                                    </div>
                                </div>
                            )
                        }) :
                        <p>No upcoming events at this time.</p>
                    }
                </div>
                <div className={'bg-light-green ' + styles.card}>
                    <HeadingTwo title={'Announcements'} className={''}/>
                    {announcements.length ?
                        announcements.map(announcement => {
                            return (
                                <div key={announcement.id} className={styles.offerWrapper}>
                                    <p><span className={styles.discount}>{announcement.title}</span><br/>
                                        {announcement.message}
                                    </p>
                                </div>
                            )
                        }) :
                        <p>There are no announcements at this time.</p>
                    }
                </div>
            </div>
            <div className={styles.eventContainer}>
                <div className={'bg-light-green ' + styles.card}>
                    <HeadingTwo title={'Latest News'} className={''}/>
                    {
                        news.slice(0, 6).map(news => {
                            const date = news.posted.split('T');

                            const datePosted = date[0].split('-');

                            const day = datePosted[2];
                            const year = datePosted[0];
                            const month = months(datePosted[1]);

                            const timePosted = date[1].split(':');

                            const hour = timePosted[0];
                            const minutes = timePosted[1];

                            return (
                                <div key={news.newsId} className={styles.eventWrapper}>
                                    <Link to={news.url}>
                                        <p className={styles.eventTitle}>{news.title}</p>
                                        <p className={styles.newsDate}>{month} {day}, {year} at {hour}:{minutes}</p>
                                        <p className={styles.newsText}>{news.description.length > 180 ?
                                            `${news.description.substring(0, 180)}...` : news.description
                                        }</p>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={'bg-light-green ' + styles.card}>
                    <HeadingTwo title={'Exclusive Offers'} className={''}/>
                    {offers.length ?
                        offers.map(offer => {
                            const endDate = offer.validity.split('T');
                            const date = endDate[0].split('-')

                            const day = date[2];
                            const month = months(date[1]);
                            const year = date[0];
                            return (
                                <div key={offer.id} className={styles.offerWrapper}>
                                    <p><span className={styles.discount}>{offer.discount} discount</span><br/>
                                        with the code <span className={styles.code}>{offer.code}</span><br/>
                                        Valid until {month} {day}, {year}<br/>
                                        At {offer.businessName}
                                    </p>
                                </div>
                            )
                        }) :
                        <p>There are no offers at this time.</p>
                    }
                    <div className={'mt-10 align-center'}>
                        <Button text={'View all Offers'} href={'/settings'} />
                    </div>
                </div>
            </div>
        </>
    );
}