import React, {useState} from 'react';
import styles from './details-event-modal.module.css'
import HeadingTwo from "./ui/heading-two";
import style from "./update-business.module.css";
import api from "../../api";

export default function DetailsJobModal({showDetails, handleCloseDetails, job, user, jobDate}) {

    const [error, setError] = useState();

    if (!showDetails) {
        return null;
    }

    let url = api;

    function handleApprove() {

        // Or you can work with it as a plain object:
        const formJson = {
            status: 'approved',
            jobId: job.jobId
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/jobStatus', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Job approved");
                    handleCloseDetails()
                    window.location.reload();
                } else {
                    setError('We couldn’t update the job. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    function handleDeny() {

        // Or you can work with it as a plain object:
        const formJson = {
            status: 'denied',
            jobId: job.jobId
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/jobStatus', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Job denied");
                    handleCloseDetails()
                    window.location.reload();
                } else {
                    setError('We couldn’t update the job. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    function handlePending() {

        // Or you can work with it as a plain object:
        const formJson = {
            status: 'pending',
            jobId: job.jobId
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/jobStatus', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Job pended");
                    handleCloseDetails()
                    window.location.reload();
                } else {
                    setError('We couldn’t update the job. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    function handleDelete() {

        const confirmDelete = window.confirm("Are you sure you want to delete this job listing?");

        if (confirmDelete) {
            // Or you can work with it as a plain object:
            const formJson = {
                jobId: job.jobId
            }

            const initPut = {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(formJson)
            };

            fetch(url + 'app/deleteJob', initPut)
                .then(response => response.json())
                .then(data => {
                    if (data.isValid) {
                        alert("Job listing deleted succesfully.");
                        window.location.reload();
                    } else {
                        setError('We couldn’t delete the job listing. Please try again.')
                    }
                })
                .catch(error => console.error(error));
        }

    }

    return (
        <div className={styles.modal}>
            <div className={styles.modalMain}>
                <div className={'section-col ' + styles.header}>
                    <HeadingTwo className={styles.heading} title={job.title}/>
                    <button className={styles.close} onClick={handleCloseDetails}>Close</button>
                </div>
                <div>
                    <p>By {job.businessName}</p>
                    <p>Posted on {jobDate}</p>
                    <p>If interested, contact us at {job.contact}.</p>
                    <p>{job.description}</p>
                </div>
                {
                    user.role === 1 ?
                        <div className={'align-center'}>
                            {job.status === 'pending' ?
                                <>
                                    <button className={styles.button + ' mr-5'} onClick={e => {
                                        handleApprove()
                                    }}>Approve
                                    </button>
                                    <button className={styles.button} onClick={e => {
                                        handleDeny()
                                    }}>Deny
                                    </button>
                                </>
                                : job.status === 'denied' ?
                                    <>
                                        <button className={styles.button + ' mr-5'} onClick={e => {
                                            handleApprove()
                                        }}>Approve
                                        </button>
                                        <button className={styles.button + ' mr-5'} onClick={e => {
                                            handleDelete()
                                        }}>Delete
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button className={styles.button + ' mr-5'} onClick={e => {
                                            handlePending()
                                        }}>Pending
                                        </button>
                                        <button className={styles.button} onClick={e => {
                                            handleDeny()
                                        }}>Deny
                                        </button>
                                    </>
                            }
                            {error ? <p className={style.error}>{error}</p> : null}
                        </div>
                        :
                        ''
                }
            </div>
        </div>
    )
}