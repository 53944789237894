import React from "react";
import Heading from "../../components/towncrier/ui/heading";
import {useLocation} from "react-router-dom";
import ParticipantsTable from "../../components/towncrier/participants-table";
import Button from "../../components/towncrier/ui/button";

export default function ParticipantsDetails() {
    let location = useLocation();

    return (
        <main className={'participants-details towncrier-content'}>
            <div className='section'>
                <Heading title={`Participants of ${location.state.title}`}/>
                <ParticipantsTable eventsId={location.state.eventsId}/>
                <div className={'align-center mt-10'}>
                    <Button text={'Go Back'} href={'..'}/>
                </div>
            </div>
        </main>
    );
}