import React, {useEffect, useState} from 'react';
import styles from "./ui/button.module.css";
import style from './update-business.module.css'
import HeadingTwo from "./ui/heading-two";
import api from "../../api";

export default function UpdateBusiness() {

    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [businessName, setBusinessName] = useState('');
    const [description, setDescription] = useState('');
    const [website, setWebsite] = useState('');
    const [phone, setPhone] = useState('');
    const [shortDesc, setShortDesc] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [category, setCategory] = useState('');
    const [imageName, setImageName] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [membershipStatus, setMembershipStatus] = useState('');
    const [businessId, setBusinessId] = useState(0);
    const [gbp, setGBP] = useState(0);
    const [error, setError] = useState();

    const url = api;

    const options = [
        {value: 'Advertising & Media', text: 'Advertising & Media'},
        {value: 'Arts, Culture & Entertainment', text: 'Arts, Culture & Entertainment'},
        {value: 'Business & Professional Services', text: 'Business & Professional Services'},
        {value: 'Computers & Telecommunications', text: 'Computers & Telecommunications'},
        {value: 'Finance & Insurance', text: 'Finance & Insurance'},
        {value: 'Government, Education & Representative', text: 'Government, Education & Representative'},
        {value: 'Home & Garden', text: 'Home & Garden'},
        {value: 'Legal', text: 'Legal'},
        {value: 'Manufacturing, Production & Wholesale', text: 'Manufacturing, Production & Wholesale'},
        {value: 'Property, Moving & Storage', text: 'Property, Moving & Storage'},
        {value: 'Retail & Speciality Retail', text: 'Retail & Speciality Retail'},
        {value: 'Transportation', text: 'Transportation'},
        {value: 'Agriculture, Fishing & Forestry', text: 'Agriculture, Fishing & Forestry'},
        {value: 'Automotive & Marine', text: 'Automotive & Marine'},
        {value: 'Charities & Community Organisations', text: 'Charities & Community Organisations'},
        {value: 'Construction Equipment & Contractors', text: 'Construction Equipment & Contractors'},
        {value: 'Restaurants, Food & Beverages', text: 'Restaurants, Food & Beverages'},
        {value: 'Health Care & Well Being', text: 'Health Care & Well Being'},
        {value: 'Nutrition and Wellness', text: 'Nutrition and Wellness'},
        {value: 'HR & Recruitment', text: 'HR & Recruitment'},
        {value: 'Lodging & Travel', text: 'Lodging & Travel'},
        {value: 'Office Supplies & Services', text: 'Office Supplies & Services'},
        {value: 'Public Utilities & Environment', text: 'Public Utilities & Environment'},
        {value: 'Security', text: 'Security'},
    ];

    function onImageChange(e) {
        if (e.target.files[0].size > 2097152) {
            alert("Image is too big! Try again");
            return;
        }
        setImageUrl(URL.createObjectURL(e.target.files[0]));
        setImageName(e.target.files[0].name);
        setImageFile(e.target.files[0]);
    }

    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        // You can pass formData as a fetch body directly:
        //fetch('/some-api', { method: form.method, body: formData });

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());
        formJson.businessId = businessId;
        formJson.image = imageName;
        formJson.membershipStatus = membershipStatus;
        let formImage;

        if (imageFile !== '') {
            formImage = new FormData();
            formImage.append('img', imageFile, imageName)
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        let initPost = {};

        if (imageFile !== '') {
            initPost = {
                method: 'POST',
                body: formImage
            };
        }

        fetch(url + 'app/updateBusiness', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    if (imageFile !== '') {
                        fetch(url + 'app/uploadImage', initPost)
                            .then(response => response.json())
                            .then(data => {
                                if (data.isValid) {
                                    alert("Business profile with updated image has been updated");
                                } else {
                                    setError('We couldn’t update your business profile with your updated image. Please check your information and try again.')
                                }
                            })
                            .catch(error => console.error(error));
                    } else {
                        alert("Business profile updated");
                    }
                } else {
                    setError('We couldn’t update your business profile. Please check your information and try again.')
                }
            })
            .catch(error => console.error(error));
    }

    function updateGBP() {
        const initPutGBP = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({gbp: gbp, businessId: businessId})
        };

        fetch(url + 'app/getGMBInfo', initPutGBP)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Your business profile will be updated shortly. Refresh the page in a minute.")
                } else {
                    setError('We couldn’t update your business profile with your GBP information. Make sure your business has a Google Business Profile.')
                }
            })
            .catch(error => console.error(error));
    }

    const getBusinessInfo = async () => {
        try {

            const response = await fetch(url + 'app/businessSelection/' + user.businessId);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBusinessName(data.business.businessName);
            setDescription(data.business.description);
            setEmail(data.business.email);
            setImageName(data.business.image);
            setImageUrl('https://towncrier.tech/uploads/' + data.business.image);
            setWebsite(data.business.website);
            setAddress(data.business.address);
            setShortDesc(data.business.shortDesc);
            setPhone(data.business.phone);
            setCategory(data.business.category);
            setBusinessId(data.business.businessId)
            setMembershipStatus(data.business.membershipStatus)
            setGBP(data.business.gbp);
        } catch (error) {
            console.error('Error fetching business data:', error);
        }
    };

    useEffect(() => {
        getBusinessInfo();
    }, []);

    return (
        <>
            <HeadingTwo title={'Business Picture'} className={''}/>
            <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
                <div>
                    <img src={imageUrl} alt={'logo'} width={150}/>
                    <input type="file" accept="image/png, image/jpg" onChange={onImageChange}/>
                </div>
                <p>PNG, JPG max size of 2MB</p>
                <HeadingTwo title={'Business Information'}/>
                <label>
                    Business Name <input className={style.mailingInput + ' search-input'}
                                         placeholder='Name' required={true} type='text'
                                         name="businessName" value={businessName}
                                         onChange={e => setBusinessName(e.target.value)}/>
                </label>
                <label>
                    Short Description (summarize your activity in one sentence) <input
                    className={style.mailingInput + ' search-input'}
                    placeholder='Short Description' required={true} type='text'
                    name="shortDesc" value={shortDesc}
                    onChange={e => setShortDesc(e.target.value)}/>
                </label>
                <label>
                    Description <textarea className={style.mailingInput + ' search-input'}
                                          placeholder='Description' required={true} rows='7'
                                          name="description" value={description}
                                          onChange={e => setDescription(e.target.value)}/>
                </label>
                <label>
                    Website <input className={style.mailingInput + ' search-input'}
                                   placeholder='Website' type='text'
                                   name="website" value={website}
                                   onChange={e => setWebsite(e.target.value)}/>
                </label>
                <label>
                    Phone Number <input className={style.mailingInput + ' search-input'}
                                        placeholder='Phone Number' required={true} type='tel'
                                        name="phone" value={phone}
                                        onChange={e => setPhone(e.target.value)}/>
                </label>
                <label>
                    Email <input className={style.mailingInput + ' search-input'}
                                 placeholder='Email' type='email'
                                 name="email" value={email}
                                 onChange={e => setEmail(e.target.value)}/>
                </label>
                <label>
                    Address <input className={style.mailingInput + ' search-input'}
                                   placeholder='Address' required={true} type='text'
                                   name="address" value={address}
                                   onChange={e => setAddress(e.target.value)}/>
                </label>
                <label>
                    Business Category
                    <div className={style.mailingInput + ' search-input'}>
                        <select id={'filter'}
                                className={''}
                                value={category}
                                required={true}
                                name="category"
                                onChange={e => setCategory(e.target.value)}>
                            {options.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                    </div>
                </label>
                <div className={"mb-5"}>
                    <p>Do you have a Google Business Profile?</p>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="googleBusinessProfile"
                                value="0"
                                checked={gbp === 0}
                                onChange={e => setGBP(Number(e.target.value))}
                            />
                            I do not have a Google Business Profile
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="googleBusinessProfile"
                                value="1"
                                checked={gbp === 1}
                                onChange={e => setGBP(Number(e.target.value))}
                            />
                            I do have a Google Business Profile (linked to my business in Midleton and area)
                        </label>
                    </div>
                    <input type="hidden" name="gbp" value={gbp}/>
                </div>
                <button className={styles.button} type="submit">Update</button>
                {error ? <p className={style.error}>{error}</p> : null}
            </form>
            {
                gbp === 1 ?
                    <>
                        <button className={style.gbpButton} onClick={updateGBP}>I updated my Google Business Profile
                        </button>
                        <p>This action will update your short description, phone number, website and address.</p>
                    </> :
                    null
            }
        </>

    );
}