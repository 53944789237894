import React from 'react'
import {Link} from "react-router-dom";
import styles from './business-card.module.css'
import {FiPhone} from "react-icons/fi";
import {TbWorldWww} from "react-icons/tb";
import {HiOutlineMail} from "react-icons/hi";
import IconText from "./icon-text";
import IconLink from "./icon-link";

export default function BusinessCard({
                                         businessId,
                                         businessName,
                                         image,
                                         address,
                                         phone,
                                         website,
                                         shortDesc,
                                         description,
                                         email,
                                         category,
                                         membershipStatus,
                                         gbp
                                     }) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    let membershipDate = membershipStatus.split('T');
    membershipDate = membershipDate[0].split('-')
    let membershipMonth = monthNames[Number(membershipDate[1] - 1)];
    let membershipDay = membershipDate[2];
    let membershipYear = membershipDate[0];

    return (
        <div className={styles.cardWrapper}>
            <div className={styles.cardHeader}>
                {image ? <img src={'https://towncrier.tech/uploads/' + image} alt={businessName + ' logo'}
                              height={150}/> : ''}
                <p>{businessName}</p>
            </div>
            <div className={styles.cardContent}>
                {email ? <IconText icon={<HiOutlineMail size={20}/>} text={email}/> : ''}
                {phone ? <IconText icon={<FiPhone size={20}/>} text={phone}/> : ''}
                {website ? <IconLink icon={<TbWorldWww size={20}/>} link={website}/> : ''}
                {membershipStatus === '0000-00-00 00:00:00' ?
                    <p>Membership Status: Lifetime membership</p>
                    :
                    <p>Membership Status: {membershipMonth} {membershipDay}, {membershipYear}</p>
                }

            </div>
            <div className={styles.cardFooter}>
                <Link to={businessName}
                      state={{
                          businessId: businessId,
                          shortDesc: shortDesc,
                          businessName: businessName,
                          image: image,
                          address: address,
                          phone: phone,
                          email: email,
                          website: website,
                          description: description,
                          category: category,
                          membershipStatus: membershipStatus,
                          gbp: gbp
                      }}>Edit</Link>
            </div>
        </div>
    );
}