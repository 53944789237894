import React, {useState} from 'react';
import styles from './details-event-modal.module.css'
import HeadingTwo from "./ui/heading-two";
import style from "./update-business.module.css";
import IconText from "./ui/icon-text";
import {HiOutlineLocationMarker} from "react-icons/hi";
import api from "../../api";

export default function DetailsEventModal({showDetails, handleCloseDetails, title, date, description, address,
                                              organiser, user, eventId, status}) {

    const [error, setError] = useState();

    if (!showDetails) {
        return null;
    }

    let url = api;

    function handleApprove() {

        // Or you can work with it as a plain object:
        const formJson = {
            status: 'approved',
            eventId: eventId
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/eventStatus', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Event approved");
                    handleCloseDetails()
                } else {
                    setError('We couldn’t update the event. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    function handleDeny() {

        // Or you can work with it as a plain object:
        const formJson = {
            status: 'denied',
            eventId: eventId
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/eventStatus', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Event denied");
                    handleCloseDetails()
                } else {
                    setError('We couldn’t update the event. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    function handlePending() {

        // Or you can work with it as a plain object:
        const formJson = {
            status: 'pending',
            eventId: eventId
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/eventStatus', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Event pended");
                    handleCloseDetails()
                } else {
                    setError('We couldn’t update the event. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    function handleDelete() {

        const confirmDelete = window.confirm("Are you sure you want to delete this event?");

        if (confirmDelete) {
            // Or you can work with it as a plain object:
            const formJson = {
                eventsId: eventId
            }

            const initPut = {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(formJson)
            };

            fetch(url + 'app/deleteEvent', initPut)
                .then(response => response.json())
                .then(data => {
                    if (data.isValid) {
                        alert("Event deleted succesfully.");
                        window.location.reload();
                    } else {
                        setError('We couldn’t delete the event. Please try again.')
                    }
                })
                .catch(error => console.error(error));
        }

    }

    return (
        <div className={styles.modal}>
            <div className={styles.modalMain}>
                <div className={'section-col ' + styles.header}>
                    <HeadingTwo className={styles.heading} title={title}/>
                    <button className={styles.close} onClick={handleCloseDetails}>Close</button>
                </div>
                <div>
                    <p>By {organiser}</p>
                    <p>{date}</p>
                    <IconText text={address} icon={<HiOutlineLocationMarker size={20}/>} />
                    <p>{description}</p>
                </div>
                {
                    user.role === 1 ?
                        <div className={'align-center'}>
                            {status === 'pending' ?
                                <>
                                    <button className={styles.button + ' mr-5'} onClick={e => {
                                        handleApprove()
                                    }}>Approve
                                    </button>
                                    <button className={styles.button} onClick={e => {
                                        handleDeny()
                                    }}>Deny
                                    </button>
                                </>
                                : status === 'denied' ?
                                    <>
                                        <button className={styles.button + ' mr-5'} onClick={e => {
                                            handleApprove()
                                        }}>Approve
                                        </button>
                                        <button className={styles.button + ' mr-5'} onClick={e => {
                                            handleDelete()
                                        }}>Delete
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button className={styles.button + ' mr-5'} onClick={e => {
                                            handlePending()
                                        }}>Pending
                                        </button>
                                        <button className={styles.button} onClick={e => {
                                            handleDeny()
                                        }}>Deny
                                        </button>
                                    </>
                            }
                            {error ? <p className={style.error}>{error}</p> : null}
                        </div>
                        :
                        ''
                }
            </div>
        </div>
    )
}