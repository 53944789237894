import React, {useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom"
import Heading from "../../components/towncrier/ui/heading";
import styles from "../../components/towncrier/ui/resource-card.module.css";
import Button from "../../components/towncrier/ui/button";
import style from "../../components/towncrier/update-business.module.css";
import api from "../../api";

export default function ResourcePage() {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [error, setError] = useState();

    let params = useParams();
    let location = useLocation();

    let url = api;
    const navigate = useNavigate();

    function handleApprove() {

        // Or you can work with it as a plain object:
        const formJson = {
            status: 'approved',
            resourcesId: location.state.id
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/resourceStatus', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Resource published");
                    navigate('/resource-library');
                } else {
                    setError('We couldn’t update the resource. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    function handleDeny() {

        // Or you can work with it as a plain object:
        const formJson = {
            status: 'denied',
            resourcesId: location.state.id
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/resourceStatus', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Resource put in the trash.");
                    navigate('/resource-library');
                } else {
                    setError('We couldn’t update the resource. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    function handlePending() {

        // Or you can work with it as a plain object:
        const formJson = {
            status: 'pending',
            resourcesId: location.state.id
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/resourceStatus', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Resource put in the drafts.");
                    navigate('/resource-library');
                } else {
                    setError('We couldn’t update the resource. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    function handleDelete() {

        const confirmDelete = window.confirm("Are you sure you want to delete this resource?");

        if (confirmDelete) {
            // Or you can work with it as a plain object:
            const formJson = {
                resourcesId: location.state.id
            }

            const initPut = {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(formJson)
            };

            fetch(url + 'app/deleteResource', initPut)
                .then(response => response.json())
                .then(data => {
                    if (data.isValid) {
                        alert("Resource deleted succesfully.");
                        window.location.reload();
                    } else {
                        setError('We couldn’t delete the resource. Please try again.')
                    }
                })
                .catch(error => console.error(error));
        }

    }

    return (
        <main className={'business-page towncrier-content ' + params.slug}>
            <div className={'section'}>
                <Heading title={location.state.title}/>
                <p className={styles.type}><span>{location.state.type}</span></p>
                <p>
                    {location.state.content.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br/>
                        </React.Fragment>
                    ))}
                </p>
                <div className={'align-center mt-10'}>
                    <Button text={'Go Back'} href={'..'}/>
                </div>
                {
                    user.role === 1 ?
                        <div className={'align-center mt-10'}>
                            {location.state.status === 'pending' ?
                                <>
                                    <button className={styles.button + ' mr-5'} onClick={e => {
                                        handleApprove()
                                    }}>Publish
                                    </button>
                                    <button className={styles.button + ' mr-5'} onClick={e => {
                                        handleDeny()
                                    }}>Trash
                                    </button>
                                    <Link className={styles.button} to={'/resource-library/update-resource'}
                                          state={{
                                              resourcesId: location.state.id,
                                              title: location.state.title,
                                              content: location.state.content,
                                              status: location.state.status,
                                              type: location.state.type,
                                              locationID: location.state.locationID
                                          }}>Edit</Link>
                                </>
                                : location.state.status === 'denied' ?
                                    <>
                                        <button className={styles.button + ' mr-5'} onClick={e => {
                                            handleApprove()
                                        }}>Publish
                                        </button>
                                        <button className={styles.button + ' mr-5'} onClick={e => {
                                            handleDelete()
                                        }}>Delete
                                        </button>
                                        <Link className={styles.button} to={'/resource-library/update-resource'}
                                              state={{
                                                  resourcesId: location.state.id,
                                                  title: location.state.title,
                                                  content: location.state.content,
                                                  status: location.state.status,
                                                  type: location.state.type,
                                                  locationID: location.state.locationID
                                              }}>Edit</Link>
                                    </>

                                    :
                                    <>
                                        <button className={styles.button + ' mr-5'} onClick={e => {
                                            handlePending()
                                        }}>Draft
                                        </button>
                                        <button className={styles.button + ' mr-5'} onClick={e => {
                                            handleDeny()
                                        }}>Trash
                                        </button>
                                        <Link className={styles.button} to={'/resource-library/update-resource'}
                                              state={{
                                                  resourcesId: location.state.id,
                                                  title: location.state.title,
                                                  content: location.state.content,
                                                  status: location.state.status,
                                                  type: location.state.type,
                                                  locationID: location.state.locationID
                                              }}>Edit</Link>
                                    </>
                            }
                            {error ? <p className={style.error}>{error}</p> : null}
                        </div>
                        :
                        ''
                }
            </div>
        </main>
    );
}