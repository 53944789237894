import React, {useEffect, useMemo, useState} from 'react';
import styles from './all-events.module.css'
import Pagination from "../pagination";
import api from "../../api";
import StatusJobCard from "./ui/status-job-card";

let PageSize = 12;

export default function Jobs() {
    const [currentPage, setCurrentPage] = useState(1);
    const [jobs, setJobs] = useState([]);
    const [user] = useState(JSON.parse(localStorage.getItem("user")));

    const url = api;

    const getAllJobs = async () => {
        try {
            const response = await fetch(url + 'app/allJobs');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) {
                setJobs(data.jobs);
            }

        } catch (error) {
            console.error('Error fetching jobs data:', error);
        }
    }

    useEffect(() => {
        getAllJobs();
    }, []);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return jobs.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, jobs]);



    const items = currentTableData.map((job) => {
        return <StatusJobCard job={job} userInfo={user} /> ;
    });

    return <>
        {
            items.length ?
                <>
                    <div className={styles.grid}>{items}</div>

                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={jobs.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </>
                :
                <p>No job listings has been published yet.</p>
        }

    </>;
}