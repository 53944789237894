import React, {useEffect, useState} from "react";
import {FaSearch} from "react-icons/fa";
import BusinessCard from "./ui/business-card";
import api from "../../api";
import styles from "./ui/business-card.module.css";

export default function SearchDirectory() {
    const options = [
        {value: '', text: 'All Sectors'},
        {value: 'Advertising & Media', text: 'Advertising & Media'},
        {value: 'Arts, Culture & Entertainment', text: 'Arts, Culture & Entertainment'},
        {value: 'Business & Professional Services', text: 'Business & Professional Services'},
        {value: 'Computers & Telecommunications', text: 'Computers & Telecommunications'},
        {value: 'Finance & Insurance', text: 'Finance & Insurance'},
        {value: 'Government, Education & Representative', text: 'Government, Education & Representative'},
        {value: 'Home & Garden', text: 'Home & Garden'},
        {value: 'Legal', text: 'Legal'},
        {value: 'Manufacturing, Production & Wholesale', text: 'Manufacturing, Production & Wholesale'},
        {value: 'Property, Moving & Storage', text: 'Property, Moving & Storage'},
        {value: 'Retail & Speciality Retail', text: 'Retail & Speciality Retail'},
        {value: 'Transportation', text: 'Transportation'},
        {value: 'Agriculture, Fishing & Forestry', text: 'Agriculture, Fishing & Forestry'},
        {value: 'Automotive & Marine', text: 'Automotive & Marine'},
        {value: 'Charities & Community Organisations', text: 'Charities & Community Organisations'},
        {value: 'Construction Equipment & Contractors', text: 'Construction Equipment & Contractors'},
        {value: 'Restaurants, Food & Beverages', text: 'Restaurants, Food & Beverages'},
        {value: 'Health Care & Well Being', text: 'Health Care & Well Being'},
        {value: 'Nutrition and Wellness', text: 'Nutrition and Wellness'},
        {value: 'HR & Recruitment', text: 'HR & Recruitment'},
        {value: 'Lodging & Travel', text: 'Lodging & Travel'},
        {value: 'Office Supplies & Services', text: 'Office Supplies & Services'},
        {value: 'Public Utilities & Environment', text: 'Public Utilities & Environment'},
        {value: 'Security', text: 'Security'},
    ];

    const [selected, setSelected] = useState(options[0].value);
    const [input, setInput] = useState("");
    const [results, setResults] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    const [filtered, setFiltered] = useState([]);

    const url = api;

    const getBusinesses = async () => {
        try {

            const response = await fetch(url + 'app/businessAdmin/');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setResults(data.business);
            setBusinesses(data.business);
        } catch (error) {
            console.error('Error fetching business data:', error);
        }
    };

    useEffect(() => {
        getBusinesses();
    }, []);

    const fetchDataInput = (value) => {
        if (value === '') {
            setResults(businesses);
            return;
        }
        const res = businesses.filter((business) => {
            return (
                value &&
                business &&
                business.businessName &&
                business.businessName.toLowerCase().includes(value)
            );
        });
        setResults(res);
    };

    const fetchDataSelect = (value) => {
        if (value === '') {
            setResults(businesses);
            return;
        }
        const res = businesses.filter((business) => {
            return (
                value &&
                business &&
                business.category &&
                business.category.includes(value)
            );
        });
        setResults(res);
    };

    const handleChangeSelect = event => {
        setSelected(event.target.value);
        setInput('');
        fetchDataSelect(event.target.value);
    };

    const handleChangeInput = event => {
        setInput(event);
        fetchDataInput(event);
    };

    useEffect(() => {
        if (results.length > 0) {
            let newList = [...results];
            if (input) {
                newList = newList.filter(i => i.businessName.toLowerCase().includes(input));
            }
            if (selected) {
                newList = newList.filter(i => i.category.includes(selected));
            }
            setFiltered(newList);
        } else {
            setFiltered(results);
        }
    }, [results, input, selected]);

    return (
        <>
            <div className={'search-container section-col'}>
                <div className={'filter-wrapper search-input mb-5 mb-0d'}>
                    <select id={'filter'} className={''} value={selected} onChange={handleChangeSelect}>
                        {options.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={'search-wrapper search-input'}>
                    <input id='search-input' placeholder="Search Directory" value={input}
                           onChange={(e) => handleChangeInput(e.target.value)}
                    />
                    <FaSearch size={20} className="search-icon"/>
                </div>
            </div>
            <div className={'search-results'}>
                {filtered.length > 0 ?
                    filtered.map(filter => (
                        <div className={styles.card} key={filter.businessId}>
                            <BusinessCard businessId={filter.businessId}
                                          businessName={filter.businessName}
                                          address={filter.address}
                                          phone={filter.phone}
                                          shortDesc={filter.shortDesc}
                                          website={filter.website}
                                          image={filter.image}
                                          description={filter.description}
                                          email={filter.email}
                                          category={filter.category}
                                          membershipStatus={filter.membershipStatus}
                                          gbp={filter.gbp}
                            />
                        </div>
                    )) :
                    <p>No results found. Please try again.</p>
                }
            </div>
        </>

    );
}