import React, {useEffect, useState} from 'react';
import styles from "./ui/button.module.css";
import style from './update-business.module.css'
import HeadingTwo from "./ui/heading-two";
import api from "../../api";
import Select from 'react-select';

export default function ManageUsersCredentials() {

    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [error, setError] = useState();
    const [members, setMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);

    const url = api;

    const getAllBusinesses = async () => {
        try {
            const response = await fetch(url + 'app/businessAdmin/');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) {
                setMembers(data.business);
            }
        } catch (error) {
            console.error('Error fetching businesses data:', error);
        }
    };

    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();
        if (!selectedMember) {
            setError('Please select a business.');
            return;
        }

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        // You can pass formData as a fetch body directly:
        //fetch('/some-api', { method: form.method, body: formData });

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());
        formJson.businessId = selectedMember.value;

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/changePasswordAdmin', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Password updated. Here is the new password: " + data.password + " Copy it and send it to the member.");
                    window.location.reload();
                } else {
                    setError('We couldn’t update the password. Please check the information sent and try again.')
                }
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getAllBusinesses();
    }, []);

    const options = members.map(member => ({
        value: member.businessId,
        label: member.businessName
    }));

    return (
        <div className={'mb-10'}>
            <HeadingTwo title={'Manage Users Credentials'} className={''}/>
            <p>Generate a new password for members who forgot theirs.</p>
            <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
                <Select
                    options={options}
                    onChange={setSelectedMember}
                    placeholder="Select a business"
                    isSearchable
                    className="mb-5"
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 12,
                        colors: {
                            ...theme.colors,
                            primary25: '#EBF0EF',
                            primary: '#063A2E',
                        },
                    })}
                    styles={{
                        control: (base) => ({
                            ...base,
                            padding: '12px 10px',  // Adds padding to the left of the input
                        }),
                        input: (base) => ({
                            ...base,
                            padding: '0px',
                        }),
                    }}
                />
                <button className={styles.button} type="submit">Update</button>
                {error ? <p className={style.error}>{error}</p> : null}
            </form>
        </div>

    );
}