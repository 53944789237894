import React, {useState} from 'react';
import styles from "./ui/button.module.css";
import style from './login-form.module.css'
import {useNavigate} from "react-router-dom";
import api from "../../api";
import Button from "./ui/button";

export default function CreateMemberForm() {

    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [membership, setMembership] = useState("0000-00-00 00:00:00");
    const [currentDate] = useState(new Date());

    const url = api;
    const navigate = useNavigate();

    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());
        formJson.category = 'Advertising & Media';
        formJson.membershipStatus = membership;
        formJson.location = user.location;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/createMember', options)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert(`Member created! The generated password to log in is ${data.password} - Use it with the email address entered to access TownCrier.`);
                    navigate('/manage-members');
                } else {
                    setError('We couldn’t create the new member. Please check the information entered and try again.')
                }
            })
            .catch(error => console.error(error));
    }

    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear() + 1;
    let day = currentDate.getDate();
    let hour = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let seconds = currentDate.getSeconds();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    if (hour < 10) hour = '0' + hour;
    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;

    return (
        <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
            <label>
                Business Name <input className={style.mailingInput + ' search-input '} placeholder='Business Name'
                                     required={true} type='text' name="businessName" value={businessName}
                                     onChange={e => setBusinessName(e.target.value)}/>
            </label>
            <label>
                Member's Full Name <input className={style.mailingInput + ' search-input '}
                                          placeholder="Member's Full Name" required={true}
                                          onChange={e => setFullName(e.target.value)}
                                          type='text' name="fullName" value={fullName}/>
            </label>
            <label>
                Email (this will be the email used to log into their account)
                <input className={style.mailingInput + ' search-input '} placeholder='Email'
                       required={true} onChange={e => setEmail(e.target.value)}
                       type='text' name="email" value={email}/>
            </label>
            <p>Membership</p>
            <label className={'mr-5'}>
                <input type="radio" value="0000-00-00 00:00:00"
                       checked={membership === "0000-00-00 00:00:00"}
                       onChange={e => setMembership(e.target.value)}/>
                Lifetime Membership
            </label>
            <label>
                <input type="radio" value={`${year}-${month}-${day} ${hour}:${minutes}:${seconds}`}
                       checked={membership === `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`}
                       onChange={e => setMembership(e.target.value)}/>
                Each year
            </label>
            <div className={'mt-10 members-button'}>
                <button className={styles.button + ' mr-5'} type="submit">Create Member</button>
                <Button text={'Go Back'} href={'..'}/>
            </div>
            {error ? <p className={style.error}>{error}</p> : null}
        </form>
    );
}