import React, {useState} from "react";
import Heading from "../../components/towncrier/ui/heading";
import {Navigate} from "react-router-dom";
import TrashedResources from "../../components/towncrier/denied-resources";
import PendingResources from "../../components/towncrier/pending-resources";

export default function ResourcesManagement() {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    if (user.role !== 1) {
        return <Navigate to={'/'}/>
    }
    return (
        <main className={'resources-management towncrier-content'}>
            <div className='section'>
                <Heading title={'Resources Management'} />
                <PendingResources user={user} />
                <TrashedResources  user={user} />
            </div>
        </main>
    );
}