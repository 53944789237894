import React, {useEffect, useState} from 'react';
import styles from "./events-offers.module.css";
import style from "./ui/button.module.css"
import HeadingTwo from "./ui/heading-two";
import api from "../../api";
import Button from "./ui/button";
import EditOfferModal from "./edit-offer-modal";

export default function ManageOffers() {

    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [offers, setOffers] = useState([]);
    const [createdOffers, setCreatedOffers] = useState([]);
    const [show, setShow] = useState(false);

    const url = api;

    const getAllOffers = async () => {
        try {
            const response = await fetch(url + 'app/allOffers');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) {
                setOffers(data.offers);
            }

        } catch (error) {
            console.error('Error fetching offers data:', error);
        }
    }

    const getUserOffers = async () => {
        try {
            const response = await fetch(url + 'app/userOffers/' + user.businessId);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) {
                setCreatedOffers(data.offers);
            }
        } catch (error) {
            console.error('Error fetching offers data:', error);
        }
    }

    useEffect(() => {
        //getBusinessInfo();
        getAllOffers();
        getUserOffers();
    }, []);

    function months(month) {
        switch (month) {
            case '01':
                return 'January';
            case '02':
                return 'February';
            case '03':
                return 'March';
            case '04':
                return 'April';
            case '05':
                return 'May';
            case '06':
                return 'June';
            case '07':
                return 'July';
            case '08':
                return 'August';
            case '09':
                return 'September';
            case '10':
                return 'October';
            case '11':
                return 'November';
            case '12':
                return 'December';
            default:
                return '';
        }
    }

    const showModal = () => {
        setShow(true);
    };
    const hideModal = () => {
        setShow(false);
    };

    return (
        <>
            <HeadingTwo title={'Manage Your Offers'} className={''}/>
            <div className={'mb-10'}>
                <Button href={'create-offer'} text={'Create an Offer'}/>
            </div>
            <div className={'bg-light-green mb-10 ' + styles.cardOffers}>
                <HeadingTwo title={'Your Created Offers'} className={''}/>
                {
                    createdOffers.length ?
                        <div className={styles.grid}>
                            {
                                createdOffers.map(offer => {
                                    const endDate = offer.validity.split('T');
                                    const date = endDate[0].split('-')

                                    const day = date[2];
                                    const month = months(date[1]);
                                    const year = date[0];

                                    return (
                                        <div key={offer.id} className={styles.offerWrapper + ' ' + styles.col}>
                                            <p><span className={styles.discount}>{offer.discount} discount</span><br/>
                                                with the code <span className={styles.code}>{offer.code}</span><br/>
                                                Valid until {month} {day}, {year}<br/>
                                                At {offer.businessName}
                                            </p>
                                            <div className={'align-center'}>
                                               <button className={style.button + ' mt-5'} onClick={e => {
                                                    showModal();
                                                }}>Edit</button>
                                            </div>
                                            <EditOfferModal show={show} handleClose={e => {
                                                hideModal();
                                            }} offer={offer} userInfo={user}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        <p>You did not create an offer yet. Create one to see it appears here.</p>
                }
            </div>
            <div className={'bg-light-green ' + styles.cardOffers}>
                <HeadingTwo title={'All Offers'} className={''}/>
                {offers.length ?
                    <div className={styles.grid}>
                        {
                            offers.map(offer => {
                                const endDate = offer.validity.split('T');
                                const date = endDate[0].split('-')

                                const day = date[2];
                                const month = months(date[1]);
                                const year = date[0];

                                return (
                                    <div key={offer.id} className={styles.offerWrapper + ' ' + styles.col}>
                                        <p><span className={styles.discount}>{offer.discount} discount</span><br/>
                                            with the code <span className={styles.code}>{offer.code}</span><br/>
                                            Valid until {month} {day}, {year}<br/>
                                            At {offer.businessName}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div> :
                    <p>There are no offers at this time.</p>
                }
            </div>
        </>

    );
}