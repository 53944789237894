import React, {useState} from "react";
import logo from '../../assets/img/towncrier-logo.png';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FaBars, FaTimes} from "react-icons/fa";
import styles from './sidebar.module.css';
import {FiLogOut} from "react-icons/fi";

export default function SideBar() {
    const [nav, setNav] = useState(false);
    const [user] = useState(JSON.parse(localStorage.getItem("user")));

    const navigate = useNavigate();
    const [authTokens, setAuthTokens] = useState(
        localStorage.getItem("tokens") || ""
    );
    const handleLogout = () => {
        localStorage.clear();
        setAuthTokens("");
        navigate('/login')
        navigate(0)
    };

    let location = useLocation();

    const links = [
        {
            id: 1,
            link: "/",
            name: "Dashboard",
        },
        {
            id: 2,
            link: "",
            name: "",
        },
        {
            id: 3,
            link: "/events-workshops",
            name: "Events & Workshops",
        },
        {
            id: 4,
            link: "/resource-library",
            name: "Resource Library",
        },
        {
            id: 5,
            link: "/job-listing",
            name: "Job Listings",
        },
        {
            id: 6,
            link: "/settings",
            name: "Settings",
        },
    ];

    if (user.role === 1) {
        links[1].link = '/manage-members';
        links[1].name = 'Manage Members';
    } else {
        links[1].link = "/business-profile";
        links[1].name = "Business Profile";
    }

    if (location.pathname === '/login' || location.pathname === '/privacy-policy') {
        return null;
    }

    return (
        <header className={styles.headerTownCrier} id={'global-header-section'}>
            <div
                className={styles.navbar}>
                <Link className={styles.headerLogo} to="/">
                    <img width={80} height={72} src={logo} alt="TownCrier logo" loading="lazy"
                         decoding="async"/>
                </Link>
                <ul className={styles.navItems}>
                    {links.map(({id, link, name}) => (
                        <li key={id} className={styles.navLink}>
                            <Link to={link}>{name}</Link>
                        </li>
                    ))}
                </ul>

                <div onClick={() => setNav(!nav)} className={styles.navHamburger}>
                    {nav ? <FaTimes size={44}/> : <FaBars size={44}/>}
                </div>

                {nav && (
                    <ul className={styles.navItemsMobile}>
                        {links.map(({id, link, name}) => (
                            <li key={id} className={styles.navLinkMobile}>
                                <Link prefetch="false" onClick={() => setNav(!nav)} to={link}>
                                    {name}
                                </Link>
                            </li>
                        ))}
                        <li className={styles.navLinkMobile} onClick={handleLogout}>Log out <FiLogOut size={20}/></li>
                    </ul>
                )}
            </div>
        </header>
    );
}
;