import React, {useEffect, useState} from 'react';
import HeadingTwo from "./ui/heading-two";
import {Gauge, gaugeClasses} from "@mui/x-charts/Gauge";
import * as XLSX from "xlsx";
import LinearProgressWithLabel from "./ui/LinearProgressWithLabel";
import styles from "./localis-benchmark.module.css"
import api from "../../api";

export default function LocalisBenchmark({
                                             businessName,
                                             businessId,
                                             benchmarkSector,
                                             overallBenchmark,
                                             gmbCategory,
                                             retrievedDate,
                                             gbp
                                         }) {
    const [error, setError] = useState('');

    let formattedDate = null;
    let url = api;

    function updateLocalisBenchmark() {

        // Or you can work with it as a plain object:
        const formJson = {
            businessName: businessName,
            businessId: businessId,
            gbp: gbp
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/updateBenchmark', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert(data.message);
                    window.location.reload();
                } else {
                    setError('We couldn’t update your local benchmark. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    if (retrievedDate != null) {
        const date = new Date(retrievedDate);
        formattedDate = date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    }

    return (
        <div>
            <HeadingTwo title={'Last Local Benchmark'} className={'mt-10'}/>
            {error && <p style={{color: 'red'}}>{error}</p>}
            {
                gbp === 0 ?
                    <p>Local benchmark unavailable. To view your local benchmark, please update your business
                        profile to indicate that you have a Google Business Profile, if applicable.</p> :
                    retrievedDate != null ?
                        <>
                            <div className={styles.gauge + " mb-5"}>
                                <div className={styles.gaugeWrapper}>
                                    <Gauge width={100} height={100} value={overallBenchmark}
                                           text={({value, valueMax}) => `${value}%`} sx={{
                                        [`& .${gaugeClasses.valueText}`]: {fontWeight: 'bold', fontStyle: 'italic'},
                                        [`& .${gaugeClasses.valueArc}`]: {fill: '#063A2E',}
                                    }}/>
                                    <p className={styles.gaugeText}>{businessName}</p>
                                </div>
                                {
                                    gmbCategory !== "none" ?
                                        <div className={styles.gaugeWrapper}>
                                            <Gauge width={100} height={100} value={benchmarkSector}
                                                   text={({value, valueMax}) => `${value}%`} sx={{
                                                [`& .${gaugeClasses.valueText}`]: {
                                                    fontWeight: 'bold',
                                                    fontStyle: 'italic'
                                                },
                                                [`& .${gaugeClasses.valueArc}`]: {fill: '#A58D08',}
                                            }}/>
                                            <p className={styles.gaugeText}>{gmbCategory} Sector</p>
                                        </div> :
                                        null
                                }
                            </div>
                            <div className={"mb-5"}>Last updated: {formattedDate}</div>
                            <button className={styles.button} onClick={e => {
                                updateLocalisBenchmark()
                            }}>Update
                            </button>
                        </> :
                        <button className={styles.button} onClick={e => {
                            updateLocalisBenchmark()
                        }}>Update Now
                        </button>
            }
        </div>
    )
}