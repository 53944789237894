import React, {useState} from 'react';
import styles from "./ui/button.module.css";
import style from './login-form.module.css'
import api from "../../api";
import HeadingTwo from "./ui/heading-two";

export default function EditBusinessForm({business}) {

    const [businessName, setBusinessName] = useState(business.businessName);
    const [description, setDescription] = useState(business.description);
    const [website, setWebsite] = useState(business.website);
    const [phone, setPhone] = useState(business.phone);
    const [shortDesc, setShortDesc] = useState(business.shortDesc);
    const [email, setEmail] = useState(business.email);
    const [address, setAddress] = useState(business.address);
    const [category, setCategory] = useState(business.category);
    const [gbp, setGBP] = useState(business.gbp);
    const [imageName, setImageName] = useState(business.image);
    const [imageUrl, setImageUrl] = useState('https://towncrier.tech/uploads/' + business.image);
    const [imageFile, setImageFile] = useState('');
    const [businessId] = useState(business.businessId);
    const [error, setError] = useState();
    const initialDate = business.membershipStatus.replace('.000Z', '');
    const [membershipStatus, setMembershipStatus] = useState(initialDate);
    const [originalDate, setOriginalDate] = useState(initialDate);

    const handleRadioChange = (value) => {
        if (value === "0000-00-00 00:00:00") {
            setMembershipStatus(value);
        } else {
            const newDate = originalDate === "0000-00-00 00:00:00" ? new Date().toISOString().slice(0, 16) : originalDate;
            setMembershipStatus(newDate);
        }
    };

    const url = api;

    const options = [
        {value: 'Advertising & Media', text: 'Advertising & Media'},
        {value: 'Arts, Culture & Entertainment', text: 'Arts, Culture & Entertainment'},
        {value: 'Business & Professional Services', text: 'Business & Professional Services'},
        {value: 'Computers & Telecommunications', text: 'Computers & Telecommunications'},
        {value: 'Finance & Insurance', text: 'Finance & Insurance'},
        {value: 'Government, Education & Representative', text: 'Government, Education & Representative'},
        {value: 'Home & Garden', text: 'Home & Garden'},
        {value: 'Legal', text: 'Legal'},
        {value: 'Manufacturing, Production & Wholesale', text: 'Manufacturing, Production & Wholesale'},
        {value: 'Property, Moving & Storage', text: 'Property, Moving & Storage'},
        {value: 'Retail & Speciality Retail', text: 'Retail & Speciality Retail'},
        {value: 'Transportation', text: 'Transportation'},
        {value: 'Agriculture, Fishing & Forestry', text: 'Agriculture, Fishing & Forestry'},
        {value: 'Automotive & Marine', text: 'Automotive & Marine'},
        {value: 'Charities & Community Organisations', text: 'Charities & Community Organisations'},
        {value: 'Construction Equipment & Contractors', text: 'Construction Equipment & Contractors'},
        {value: 'Restaurants, Food & Beverages', text: 'Restaurants, Food & Beverages'},
        {value: 'Health Care & Well Being', text: 'Health Care & Well Being'},
        {value: 'Nutrition and Wellness', text: 'Nutrition and Wellness'},
        {value: 'HR & Recruitment', text: 'HR & Recruitment'},
        {value: 'Lodging & Travel', text: 'Lodging & Travel'},
        {value: 'Office Supplies & Services', text: 'Office Supplies & Services'},
        {value: 'Public Utilities & Environment', text: 'Public Utilities & Environment'},
        {value: 'Security', text: 'Security'},
    ];

    function onImageChange(e) {
        if (e.target.files[0].size > 2097152) {
            alert("Image is too big! Try again");
            return;
        }
        setImageUrl(URL.createObjectURL(e.target.files[0]));
        setImageName(e.target.files[0].name);
        setImageFile(e.target.files[0]);
    }

    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        // You can pass formData as a fetch body directly:
        //fetch('/some-api', { method: form.method, body: formData });

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());
        formJson.businessId = businessId;
        formJson.image = imageName;
        formJson.membershipStatus = membershipStatus;
        formJson.gbp = gbp;

        let formImage;

        if (imageFile !== '') {
            formImage = new FormData();
            formImage.append('img', imageFile, imageName)
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        let initPost = {};

        if (imageFile !== '') {
            initPost = {
                method: 'POST',
                body: formImage
            };
        }

        fetch(url + 'app/updateBusiness', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    if (imageFile !== '') {
                        fetch(url + 'app/uploadImage', initPost)
                            .then(response => response.json())
                            .then(data => {
                                if (data.isValid) {
                                    alert("Business profile with updated image has been updated");
                                } else {
                                    setError('We couldn’t update the business profile with your updated image. Please check your information and try again.')
                                }
                            })
                            .catch(error => console.error(error));
                    } else {
                        alert("Business profile updated");
                    }
                } else {
                    setError('We couldn’t update the business profile. Please check your information and try again.')
                }
            })
            .catch(error => console.error(error));
    }

    return (
        <>
            <HeadingTwo title={'Business Picture'} className={''}/>
            <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
                <div className={"image-wrapper"}>
                    <img src={imageUrl} alt={'logo'} width={150}/>
                    <input type="file" accept="image/png, image/jpg" onChange={onImageChange}/>
                </div>
                <p>PNG, JPG max size of 2MB</p>
                <HeadingTwo title={'Business Information'}/>
                <label>
                    Business Name <input className={style.mailingInput + ' search-input'}
                                         placeholder='Name' required={true} type='text'
                                         name="businessName" value={businessName}
                                         onChange={e => setBusinessName(e.target.value)}/>
                </label>
                <label>
                    Short Description (summarize your activity in one sentence) <input
                    className={style.mailingInput + ' search-input'}
                    placeholder='Short Description' required={true} type='text'
                    name="shortDesc" value={shortDesc}
                    onChange={e => setShortDesc(e.target.value)}/>
                </label>
                <label>
                    Description <textarea className={style.mailingInput + ' search-input'}
                                          placeholder='Description' required={true} rows='7'
                                          name="description" value={description}
                                          onChange={e => setDescription(e.target.value)}/>
                </label>
                <label>
                    Website <input className={style.mailingInput + ' search-input'}
                                   placeholder='Website' type='text'
                                   name="website" value={website}
                                   onChange={e => setWebsite(e.target.value)}/>
                </label>
                <label>
                    Phone Number <input className={style.mailingInput + ' search-input'}
                                        placeholder='Phone Number' required={true} type='tel'
                                        name="phone" value={phone}
                                        onChange={e => setPhone(e.target.value)}/>
                </label>
                <label>
                    Email <input className={style.mailingInput + ' search-input'}
                                 placeholder='Email' type='email'
                                 name="email" value={email}
                                 onChange={e => setEmail(e.target.value)}/>
                </label>
                <label>
                    Address <input className={style.mailingInput + ' search-input'}
                                   placeholder='Address' required={true} type='text'
                                   name="address" value={address}
                                   onChange={e => setAddress(e.target.value)}/>
                </label>
                <label>
                    Business Category
                    <div className={style.mailingInput + ' search-input'}>
                        <select id={'filter'}
                                className={''}
                                value={category}
                                required={true}
                                name="category"
                                onChange={e => setCategory(e.target.value)}>
                            {options.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                    </div>
                </label>
                <p>Membership</p>
                <label className={'mr-5'}>
                    <input
                        type="radio"
                        value="0000-00-00 00:00:00"
                        checked={membershipStatus === "0000-00-00 00:00:00"}
                        onChange={() => handleRadioChange("0000-00-00 00:00:00")}
                    />
                    Lifetime Membership
                </label>
                <label>
                    <input
                        type="radio"
                        value={originalDate}
                        checked={membershipStatus !== "0000-00-00 00:00:00"}
                        onChange={() => handleRadioChange("yearly")}
                    />
                    Each year
                </label>
                {membershipStatus !== "0000-00-00 00:00:00" && (
                    <div className={'mt-5'}>
                        <label>
                            Membership Date{' '}
                            <input
                                className={style.mailingInput + ' search-input'}
                                placeholder="Address"
                                required={true}
                                type="datetime-local"
                                value={membershipStatus}
                                onChange={e => {
                                    setMembershipStatus(e.target.value);
                                    setOriginalDate(e.target.value);
                                }}
                            />
                        </label>
                    </div>
                )}
                <div className={'mt-5'}>
                    <button className={styles.button} type="submit">Update</button>
                    {error ? <p className={style.error}>{error}</p> : null}
                </div>
            </form>
        </>

    );
}