import React, {useState} from 'react';
import styles from "./ui/button.module.css";
import style from './update-business.module.css'
import HeadingTwo from "./ui/heading-two";
import api from "../../api";
import {FaEye, FaEyeSlash} from "react-icons/fa";

export default function ManageCredentials() {

    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState();
    const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);

    const url = api;

    const toggleCurrentPasswordVisibility = () => {
        setIsCurrentPasswordVisible((prevVisibility) => !prevVisibility);
    };
    const toggleNewPasswordVisibility = () => {
        setIsNewPasswordVisible((prevVisibility) => !prevVisibility);
    };

    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        // You can pass formData as a fetch body directly:
        //fetch('/some-api', { method: form.method, body: formData });

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());
        formJson.id = user.id;

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/changePassword', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isMatching === false) {
                    setError('The current password you entered is incorrect. Please try again. Contact an administrator if you forgot your password.')
                } else if (data.isValid) {
                    alert("Password updated.");
                    window.location.reload();
                } else {
                    setError('We couldn’t update your password. Please check your information and try again.')
                }
            })
            .catch(error => console.error(error));
    }

    return (
        <div className={'mb-10'}>
            <HeadingTwo title={'Credentials'} className={''}/>
            <p></p>
            <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
                <label>
                    Current Password
                    <div className={style.mailingInput + ' search-input password-input'}>
                        <input
                               placeholder='Current Password' required={true} type={isCurrentPasswordVisible ? 'text' : 'password'}
                               name="currentPassword" value={currentPassword}
                               onChange={e => setCurrentPassword(e.target.value)}/>
                        <span onClick={toggleCurrentPasswordVisibility}
                              style={{ cursor: 'pointer' }}>{isCurrentPasswordVisible ? <FaEyeSlash /> : <FaEye />}</span>
                    </div>
                </label>
                <label>
                    New Password
                    <div className={style.mailingInput + ' search-input password-input'}>
                        <input
                            placeholder='New Password' required={true} type={isNewPasswordVisible ? 'text' : 'password'}
                            name="newPassword" value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}/>
                        <span onClick={toggleNewPasswordVisibility}
                              style={{cursor: 'pointer'}}>{isNewPasswordVisible ? <FaEyeSlash/> : <FaEye/>}</span>
                    </div>
                </label>
                <button className={styles.button} type="submit">Update</button>
                {error ? <p className={style.error}>{error}</p> : null}
            </form>
        </div>

    );
}