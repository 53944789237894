import React, {useState} from 'react';
import styles from "./ui/button.module.css";
import style from './login-form.module.css'
import {useNavigate} from "react-router-dom";
import api from "../../api";
import Button from "./ui/button";

export default function CreateAdminForm() {

    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const url = api;
    const navigate = useNavigate();

    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());
        formJson.location = user.location;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/createAdmin', options)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert(`Administrator created! The generated password to log in is ${data.password} - Use it with the email address entered to access TownCrier.`);
                    navigate('/manage-members');
                } else {
                    setError('We couldn’t create the new administrator. Please check the information entered and try again.')
                }
            })
            .catch(error => console.error(error));
    }

    return (
        <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
            <label>
                Administrator's Full Name <input className={style.mailingInput + ' search-input '}
                                          placeholder="Administrator's Full Name" required={true}
                                          onChange={e => setFullName(e.target.value)}
                                          type='text' name="fullName" value={fullName}/>
            </label>
            <label>
                Email (this will be the email used to log into their account)
                <input className={style.mailingInput + ' search-input '} placeholder='Email'
                       required={true} onChange={e => setEmail(e.target.value)}
                       type='text' name="email" value={email}/>
            </label>
            <p>Password will be generated automatically upon creation.</p>
            <div className={'mt-10 members-button'}>
                <button className={styles.button + ' mr-5'} type="submit">Create Member</button>
                <Button text={'Go Back'} href={'..'}/>
            </div>
            {error ? <p className={style.error}>{error}</p> : null}
        </form>
    );
}